export default {
    status: {
        save_success: 'Data saved successfully',
        save_error: 'Error saving data',
        sms_sent: 'SMS message with verification code sent',
        sent_error: 'Error sending verification code',
        loading_error: 'Error loading data',
        data_copied: 'Data copied',
        password_restore_request_success: 'A password recovery email has been sent to your email',
        password_restore_request_error: 'Error sending password reset email',
        payment_remove_success: 'Payment system "{name}" disabled',
        payment_remove_error: 'Payment system "{name}" disconnection error',
        webhook_added: 'Webhook added successfully',
        webhook_removed: 'Webhook removed successfully',
        webhook_added_error: 'Error adding Webhook',
        webhook_removed_error: 'Webhook removal failed',
        integration_launched: 'Integration launched successfully',
        integration_stopped: 'Integration stopped successfully',
        integration_launched_error: 'Integration launch failed',
        integration_stopped_error: 'Integration stopped error',
        source_archived: 'Source added to archive',
        source_recovered: 'Source restored from archive',
        source_archived_error: 'Error archiving source',
        source_recovered_error: 'Error while restoring source',
        source_removed: 'Source removed',
        source_removed_error: 'Error removing source',
        source_added: 'Source added',
        season_removed: 'Season removed',
        season_removed_error: 'Error removing season',
        balance_redirect_error: 'Error redirecting to payment page',
        user_is_revoke: 'The invitation for user with email {email} has been revoked',
        user_is_locked: 'User {user} is locked',
        user_is_locked_error: 'User locked error: {user}',
        user_is_unlocked: 'User {user} has been unlocked',
        user_is_unlocked_error: 'Error restoring user: {user}',
        user_invited: 'Invitation Sent',
        user_invited_error: 'Error sending invitation',
        email_confirmation_canceled: 'New Email Confirmation Canceled',
        metro_loading_error: 'Error loading metro station list',
        district_loading_error: 'Error loading list of districts',
        season_remove_success: '"{title}" tab removed',
        season_remove_error: 'Error removing tab "{title}"',
        photo_remove_error: 'Error when deleting a photo',
        archived_success: 'Object "{name}" added to archive',
        restored_success: 'Object "{name}" restored from archive',
        archived_error: 'Error adding object "{name}" to archive',
        restored_error: 'Error while restoring object "{name}" from archive',
        data_update_error: 'Data update failed',
        photo_upload_error: 'Error uploading file: {name}'
    },
    statuses : {
        booked : 'Booked',
        request : 'Request',
        canceled : 'Canceled',
        deleted : 'Deleted',
        additional_is_delete: '[deleted]',
    },
    left_side: {
        calendar : 'Calendar',
        clients_and_reservations: 'Clients and reservations',
        partners : 'Partners',
        channel_manager : 'Channel manager',
        settings : 'Settings',
        faq : 'Knowledge base',
        news_and_wishes : 'News and wishes',
        statistics : 'Statistics',
    },
    form: {
        login: 'Login',
        phone_or_email: 'Phone or email',
        password: 'Password',
        remembe_me: 'Password',
        login_button: 'Login',
        registration: 'Registration',
        forgot_password: 'Forgot your password?',
        no_sms: 'Didn\'t get the SMS confirmation code?',
        no_email: 'Did you not receive an email with instructions for confirming the email address?',
        unknown_error: 'Unknown error',
    },
    navbar : {
        cart : 'Cart',
        add : 'Add',
        add_apartment : 'Add Apartment',
        add_expense : 'Add Expense',
        settings : 'Settings',
        logout : 'Logout',
        logout_confirm : 'Logout?',
        return_to_old_chessmate: 'Return to old chessmate',
        login_by_manager: 'Login by manager',
        work_time: '(from 8am to 8pm)',
        write_to_support: 'Write to technical support',
    },
    messages_short_list : {
        no_messages : 'No messages.',
        new_count : 'no new messages | {count} new message',
        unread_count : 'All messages | All messages ({count} unread',
    },
    messages_full_list : {
        title : 'All messages',
        read_all : 'Set read all',
        close_modal : 'Close',
    },
    cart : {
        title : 'Send by email and SMS',
        title_link : 'Link to options',
        history_load : 'History',
        history_table_date : 'Date',
        history_table_phone : 'Phone',
        history_table_email : 'Email',
        history_table_source : 'Source',
        history_table_select : 'Select',
        get_link : 'Get link',
        send : 'Send',
        close : 'Close',
        copy : 'Copy',
        clear : 'Clear cart',
        arrival : 'Arrival',
        departure : 'Departure',
        email : 'Email',
        buy_more : 'Buy more',
        sms_left: 'SMS left: {count}',
        lifetime: 'Link lifetime',
        lifetime_days: 'Unlimited | 1 day | {count} days',
        amount: '@:agencyCurrency per day',
        guests: 'Guests',
        extra_charge_label: 'Additional daily charge',
        extra_charge: '{price} {type} per day',
        add: 'Add',
        added_extra_charge: 'Added extra',
        empty: 'Select items in the calendar to send a selection to the guest',
        whatsapp: 'WhatsApp',
        sms: 'sms',
        send_email: 'Send to Email',
        guest_phone: 'Guest phone',
        cart_is_send: 'Cart sent',
        need_phone: 'You must enter a phone number',
        whatsapp_error: 'Error creating link',
    },
    expense : {
        title : 'New expense',
        date : 'Date',
        sum : 'Amount',
        category : 'Category of expense',
        category_placeholder : 'Show more categories',
        add_category : 'Title of new category',
        comment : 'Comment',
        apartments : 'Apartments',
        apartments_placeholder : 'Search by title',
        apartments_select_all : 'Total agency expenses',
        cancel : 'Close',
        save : 'Add expense',
        saving : 'Adding expense...',
        new_category_name : 'Category name',
    },
    date_picker : {
        default: 'Not set',
        invalid_time: 'Wrong time format',
    },
    date_picker_simple : {
        default: 'Not set',
    },
    tags: {
        tags_list: 'List of tags',
        title: 'Available tags:',
        close: 'Close',
        guest_name: 'Guest name',
        checkin_date: 'Check in date',
        checkout_date: 'Checkout date',
        address: 'Object address',
        address_eng: 'Object address (transliteration)',
        how_to_get: 'How to get there (configured in the object card)',
        manager_name: 'Name of the check-in manager (configurable in the property card)',
        manager_name_eng: 'Accommodation manager name (transliteration)',
        manager_phone: 'Phone of the accommodation manager (configurable in the property card)',
        agency_name: 'Agency name',
        agency_phone: 'Agency phone',
        agency_email: 'Agency email',
        links_booking: 'Link for prepayment',
        links_booking_eng: 'Link for prepayment (english page)',
        wait_time: 'Payment due date',
        is_airbnb: 'Display content for Airbnb bookings only',
        is_booking_com: 'Display content only for bookings from Booking.com',
        is_ostrovok: 'Display content only for reservations from Ostrovok.ru',
        is_oktogo: 'Display content only for bookings from Oktogo.ru',
        is_mention_prepaid: 'Display content for prepaid bookings only',
        is_agency_prepaid_settings: 'Show content only if agency has payments set up'
    },
    attributes : {
        default : 'Any',
        reset : 'Reset all attributes',
    },
    modal_seasons: {
        title_edit: 'Season Edit',
        title_add: 'Adding a season',
        name: 'Name',
        season_start: 'Season start date',
        season_end: 'Season end date',
        add: 'Add',
        added: 'Added...',
        edit: 'Edit',
        edited: 'Edited...',
        close: 'Close'
    },
    drop_zone: {
        drag_files: 'Drag files',
        to_temp: 'to selection',
        choose_files: 'Choose files',
        or_drag: 'or drag them to the selection',
        max_size: 'Max file size: {count}Mb',
        types: 'Allowed types:',
        loaded: 'Images uploaded: {from} from {to}',
    },
    alert_modal: {
        cancel: 'Cancel',
        confirm: 'Confirm'
    },
    time_picker: {
        reset: 'Reset'
    },
    text_editor: {
        heading: 'Heading {number}',
        save: 'Save',
        add_link: 'Add link: ',
        visit_url: 'Go to link:',
        edit: 'Edit',
        remove: 'Remove'
    },
    filezone: {
        label: 'Select Files',
        max_count: 'Max files: {count}'
    },
    days: {
        today: 'today',
        tomorrow: 'tomorrow',
        yesterday: 'yesterday',
    },
    period_select: {
        this_week: 'This week',
        last_week: 'Last week',
        this_month: 'This month',
        last_month: 'Last month',
        past_three_months: 'Past 3 months',
        this_year: 'This year',
        last_year: 'Last year',
        all_time: 'All time',
    },
    base_table: {
        total: 'Total',
    },
    unknown_error: 'Unknown error, please try again',
    connection_error: 'No internet signal',
    timeout_error: 'Request timed out, try again',
    hide_password: 'Hide password',
    show_password: 'Show password',
    custom_timer: 'You can resend the code after {time}',
    event_form : {
        title_create : 'New event',
        title_update : 'Update',
        tab_create_common : 'Create booked',
        tab_create_special : 'Configure special',
        object : 'Apartment',
        load_not_found: 'Not found',
        create_error: 'Error creating booking',
    },
    event_form_common : {
        tab_info_title : 'Info',
        tab_moneta_title : 'Payment (Moneta)',
        tab_payment_title : 'Payment (WalletOne)',
        tab_history_title : 'History',
        tab_automessages_title : 'Messages',
    },
    event_form_automessages_connect : {
        title : 'Connect auto messages',
        text : 'Automate and save time. Reduce the number of manual processes and errors committed by a person!',
        more : 'Learn more',
    },
    event_form_automessages: {
        sent : 'Sent {date}',
        failed : 'Failed',
        will_be_sent : 'Will be sent {date}',
        whatsapp : 'WhatsApp {phone}',
        email : 'Email {email}',
        was_read : 'Read',
        send_now : 'Send now',
        send_email : 'Send to email',
    },
    event_form_common_history : {
        title_version : 'Version',
        title_updates : 'Updates',
        event: 'Event',
        version : 'Version',
        updates : 'Updates',
        ip : 'IP',
        user : 'User',
        user_full_name : 'Name',
        user_email : 'Email',
        field : 'Field',
        is_was : 'It was',
        became : 'Became',
    },
    event_form_common_wallet : {
        no_data : 'No data available',
        amount: 'Amount',
        status: 'Current status',
        card_number: 'Card number',
        bind_card_number: 'The current number of the linked card',
        update_date: 'Time of last change',
    },
    event_form_common_moneta: {
        no_data : 'No data available',
        amount: 'Amount',
        human_state: 'Current status',
        updated_at: 'Time of last change',
        withdrew_at: 'Sent to SBP',
        refunded_at: 'Returned',
        paid_at: 'Payment time',
        refund: 'Make an refund',
        refund_confirm: 'Make a refund?',
    },
    event_form_special_create: {
        cancel : 'Cancel',
        save : 'Save',
        saving : 'Saving...',
        close_without_saving: 'Close without saving',
        restore_to_default: 'Restore to default',
        amount : 'Price per day',
        min_stay_through : 'Min. booking period (day)',
        can_booking : 'Can booking',
        can_in : 'Can enter',
        can_out : 'Can leave',
        apartments_select : 'Select...',
        rates: 'Rates',
        common_value: 'Common value',
        all_rates: 'All rates',
        no_rates: 'No rates',
        no_default_rates: 'No default rates',
        select_all: 'Select all',
        deselect_all: 'Unselect all',
        restore_rates: 'Restore rates to default',
        select_date_until: 'select',
        save_not_found: 'Object not found',
        current_min_stay: 'Current min. stay',
        min_stay_window: '{quantity} - booking window',
        min_stay_actual: '{quantity} - special price',
        min_stay_dynamic: '{quantity} - from dynamic restrictions',
        min_stay_base: '{quantity} - base',
        arrival_departure_close_warning: '{Important!|{important_text}} The entry and exit ban is not transmitted to all sites. {More details|{more_info}}.',
    },
    event_form_common_info : {
        cancel : 'Cancel',
        save_create : 'Create',
        saving_create : 'Creating...',
        save_update : 'Save changes',
        saving_update : 'Saving changes...',
        delete : 'Delete',
        room : 'Room',
        price_by_day : 'Nightly',
        amount : 'Amount',
        date_in : 'Arrive',
        date_out : 'Depart',
        days_count : 'Total: {count} nights',
        payed : 'Payed',
        deposit : 'Deposit',
        status : 'Status',
        info_about_dates_free : 'Dates will be available for booking.',
        phone : 'Phone',
        additional_phone : 'Res. phone',
        fio : 'Name',
        notes : 'Notes',
        email : 'Email',
        source : 'Source',
        files : 'Files',
        color : 'Color',
        color_and_icon : 'Color and icon',
        reset_visual : 'Reset',
        send_confirm_email : 'Send a email confirmation',
        send_link_prepaid : 'Send link to prepayment',
        no_card_data : 'You don\'t have any payment systems set up, to set it up, go to the tab "Settings" > "Accept payments"',
        status_booked : 'Booked',
        status_request : 'Booking request',
        status_canceled : 'Cancellation',
        apartments_select : 'Select...',
        rooms_select : 'Select...',
        sources_select : 'Select...',
        confirm_delete : 'Delete?',
        no_email_on_confirm: 'Empty field',
        add_payment: 'Add payment',
        save_not_found: 'Not found',
        external_title:  'Booked {title}',
        external_show_more:  'Show more',
        external_hide_more:  'Hide more',
        add: 'Add',
        confirm_email: 'Customer email confirmation',
        yandex_informer: 'Any changes to this reservation will not affect the reservation in the Yandex Travel channel. For actions with the original booking, please contact the support of the Yandex Travel channel',
        automessages_enabled: 'Send auto messages',
    },
    event_info_payments_container: {
        remove_fine_or_refund_confirm_message: 'Pay attention! Records of the return of the deposit and the fine will be deleted',
        remove_payment_confirm_message: 'Pay attention! The record of the deposit taken will be deleted'
    },
    event_info_payments_form : {
        amount: 'Amount',
        fine_amount: 'Fine',
        note: 'Comment',
        email: 'Guest Email',
        link: 'Link',
        link_info: 'Will be available after payment is saved',
        link_inactive: 'Link is inactive',
        account: 'Account',
        agency_balance_error: 'There are not enough funds on the account to pay for the “Refund” service',
        available_for_withdrawal: '(available for withdrawal {sum} @:agencyCurrency)',
        top_up: 'Top up',
        select_account: 'Select account',
        create_payment: 'Create payment',
        create_deposit: 'Create deposit',
        refund_deposit: 'Refund deposit',
        refund_payment: 'Refund payment',
        save_payment: 'Save payment',
        save_deposit: 'Save deposit',
        cancel: 'Cancel',
        copy: 'Copy',
        send_email: 'Send payment link to guest\'s e-mail',
        get_link: 'Get payment link',
        manual_payment: 'Record receipt of payment manually',
        manual_refund_payment: 'Record payment refund manually',
        manual_deposit_refund: 'Manually record deposit refund',
        manual_deposit: 'Record manual deposit receipt',
        payment_to_card: 'Return payment to card',
        deposit_to_card: 'Return deposit to card',
        payment: 'Payment',
        select_payment: 'Select payment',
        sum_of_date: '{sum} from {date}',
        stay: 'stay with you',
        fine: 'will be returned to the guest',
        guest_email: 'Guest e-mail:',
        no_invoice_error: 'You must select a payment',
        payment_settings: 'Accept payments',
        deposit: 'deposit',
        add: 'add',
        refund: 'refund',
        moneta_active: 'Moneta payment system',
        no_payment_systems: 'configured payment systems',
        deposit_link_info: {
            text: 'Fee of {fee} will be debited for receiving a deposit using the "Moneta" service.',
            more: 'More',
            no_card_data : 'You don\'t have {kind}, currently you can {action} {type} manually, go to the tab to connect and configure',
            canceled_event : 'Cancelled bookings can only be {action} {type} manually',
        },
        informers: {
            payments_moneta_to_manual: 'Pay attention! The entire payment will be withdrawn to your SBP account, you need to make a refund to the guest yourself.',
            moneta_to_payment_system: 'Pay attention! The entire amount will be returned to the guest\'s card.',
            yandex_kassa_to_manual: 'Pay attention! The entire payment will remain on your Yukassa account, you need to make a refund to the guest yourself.',
            payments_manual_to_manual: 'Pay attention! You will need to handle the refund to the guest yourself.',
            moneta_to_payment_system_full_amount: 'Pay attention! The funds will be credited to the guest\'s card within 3-5 days.',
            moneta_to_payment_system_has_fine_amount: 'Pay attention! The funds will be credited to the guest\'s card within 3-5 days, the fine will be withdrawn to your SBP account.',
            deposits_moneta_to_manual: 'Pay attention! The entire amount of the deposit will be withdrawn to your SBP account, you need to return the money to the guest yourself.',
            deposits_manual_to_manual: 'Pay attention! You will need to handle the refund to the guest yourself.',
        }
    },
    event_info_payments_table: {
        refund: 'refund',
        fine: 'fine',
        add_payment: 'Add payment',
        add_deposit: 'Add deposit',
        refund_payment: 'Refund payment',
        refund_deposit: 'Refund deposit',
        edit_payment: 'Edit payment',
        edit_deposit: 'Edit deposit',
        payment_link: 'Payment Link:',
        guest_email: 'Guest email:',
        get_deposits_on_link: 'Accept deposits via link online',
        more_details: 'Learn more',
        no_access: {
            payments_refund: 'There are no refundable payments for this booking',
            deposits_refund: 'There is no refundable deposit for this armor',
            deposits_create: 'Only one deposit allowed per booking',
        },
        payment_link_info: 'Pay attention! The payment link becomes active only after saving the reservation. Please save the booking before sending the link to the guest.',
        copy: 'Copy to clipboard',
        send_whataspp: 'Send to WhatsApp',
        copied: 'Copied',
    },
    event_info_payments : {
        no_invoices : 'No invoices specified.',
        date : 'Date',
        amount : 'Amount',
        invoice : 'Invoice',
        comment : 'Comment',
        paid: 'Paid',
        deposit: 'Deposit',
        debt: 'Debt',
        overpayment: 'Overpayment',
        collapse: 'Collapse',
        expand: 'Expand',
        add: 'Add pledge',
        payment: 'Add payment',
        fill_or_refund: 'Refund deposit / Fine',
        refund: 'Refund'
    },
}
