export default {
    status: {
        save_success: 'Данные успешно сохранены',
        save_error: 'Ошибка при сохранении данных',
        sms_sent: 'SMS сообщение с кодом подтверждения отправлено',
        sent_error: 'Ошибка при отправке кода подтверждения',
        loading_error: 'Ошибка при загрузке данных',
        data_copied: 'Данные скопированы',
        password_restore_request_success: 'Письмо для на восстановление пароля отправлено Вам на почту',
        password_restore_request_error: 'Ошибка при отправке письма для восстановления пароля',
        payment_remove_success: 'Платежная система "{name}" отключена',
        payment_remove_error: 'Ошибка отключения платежной системы "{name}"',
        webhook_added: 'Webhook успешно добавлен',
        webhook_removed: 'Webhook успешно удален',
        webhook_added_error: 'Ошибка добавления Webhook',
        webhook_removed_error: 'Ошибка удаления Webhook',
        integration_launched: 'Интеграция успешно запущена',
        integration_stopped: 'Интеграция успешно остановлена',
        integration_refreshed: 'Интеграция успешно обновлена',
        integration_launched_error: 'Ошибка запуска интеграции',
        integration_stopped_error: 'Ошибка остановки интеграции',
        source_archived: 'Источник добавлен в архив',
        source_recovered: 'Источник восстановлен из архива',
        source_archived_error: 'Ошибка при архивации источника',
        source_recovered_error: 'Ошибка при восстановлении источника',
        source_removed: 'Источник удален',
        source_removed_error: 'Ошибка при удалении источника',
        source_added: 'Источник добавлен',
        season_removed: 'Сезон удален',
        season_removed_error: 'Ошибка удаления сезона',
        balance_redirect_error: 'Ошибка перехода на страницу оплаты',
        user_is_revoke: 'Приглашение для пользователя с почтой {email} было отозвано',
        user_is_locked: 'Пользователь {user} заблокирован',
        user_is_locked_error: 'Ошибка блокировки пользователя: {user}',
        user_is_unlocked: 'Пользователь {user} восстановлен',
        user_is_unlocked_error: 'Ошибка восстановления пользователя: {user}',
        user_invited: 'Приглашение отправлено',
        user_invited_error: 'Ошибка отправки приглашения',
        email_confirmation_canceled: 'Подтверждение нового Email отменено',
        metro_loading_error: 'Ошибка загрузки списка станций метро',
        district_loading_error: 'Ошибка загрузки списка районов',
        season_remove_success: 'Вкладка "{title}" удалена',
        season_remove_error: 'Ошибка при удалении вкладки "{title}"',
        photo_remove_error: 'Ошибка при удалении фотографии',
        archived_success: 'Объект "{name}" добавлен в архив',
        restored_success: 'Объект "{name}" восстановлен из архива',
        archived_error: 'Ошибка при добавлении объекта "{name}" в архив',
        restored_error: 'Ошибка при восстановлении объекта "{name}" из архива',
        data_update_error: 'Ошибка обновления данных',
        photo_upload_error: 'Ошибка при загрузке файла: {name}'
    },
    statuses : {
        booked : 'Бронь',
        request : 'Заявка',
        canceled : 'Отмена',
        deleted : 'Удалено',
        additional_is_delete: '[удалено]',
    },
    left_side: {
        calendar : 'Календарь',
        clients_and_reservations: 'Клиенты и брони',
        partners : 'Партнеры',
        channel_manager : 'Менеджер каналов',
        settings : 'Настройки',
        faq : 'База знаний',
        news_and_wishes : 'Новости и пожелания',
        statistics : 'Статистика',
    },
    form: {
        login: 'Вход',
        phone_or_email: 'Телефон или email',
        password: 'Пароль',
        remembe_me: 'Запомнить меня',
        login_button: 'Войти',
        registration: 'Регистрация',
        forgot_password: 'Забыли пароль?',
        no_sms: 'Не получили SMS код подтверждения?',
        no_email: 'Не получили письмо с инструкцией для подтверждения email адреса?',
        unknown_error: 'Неизвестная ошибка',
    },
    navbar : {
        cart : 'Корзина',
        add : 'Добавить',
        add_apartment : 'Добавить объект',
        add_expense : 'Добавить расход',
        settings : 'Настройки',
        logout : 'Выйти',
        logout_confirm : 'Выйти из системы?',
        return_to_old_chessmate: 'Вернуться к старой версии шахматки',
        login_by_manager: 'Войти под менеджером',
        work_time: '(с 8 до 20)',
        write_to_support: 'Написать в техподдержку',
    },
    messages_short_list : {
        no_messages : 'Сообщений нет.',
        new_count : 'нет новых сообщений | 1 новое сообщение | {count} новое сообщение | {count} новых сообщения | {count} новых сообщений',
        unread_count : 'Все сообщения | Все сообщения (1 непрочитанное) | Все сообщения ({count} непрочитанное) | Все сообщения ({count} непрочитанных) | Все сообщения ({count} непрочитанных)',
    },
    messages_full_list : {
        title : 'Все сообщения',
        read_all : 'Отметить как прочитанные',
        close_modal : 'Закрыть',
    },
    cart: {
        title: 'Корзина',
        title_link : 'Ссылка на корзину',
        history_load : 'История',
        history_table_date : 'Дата',
        history_table_phone : 'Телефон',
        history_table_email : 'Email',
        history_table_source : 'Источник',
        history_table_select : 'Выбрать',
        get_link : 'Получить ссылку',
        send : 'Отправить',
        close : 'Закрыть',
        copy : 'Скопировать',
        clear : 'Очистить корзину',
        arrival : 'Заезд',
        departure : 'Выезд',
        email : 'Email',
        buy_more : 'Купить еще',
        sms_left: 'Осталось {count} СМС',
        lifetime: 'Срок ссылки',
        lifetime_days: 'Без ограничений | 1 день | {count} день | {count} дня | {count} дней',
        amount: '@:agencyCurrency в сутки',
        guests: 'Гостей',
        extra_charge_label: 'Наценка к стоимости суток',
        extra_charge: '{price} {type} в сутки',
        add: 'Добавить',
        added_extra_charge: 'Добавлена наценка',
        empty: 'Выберите объекты в календаре, чтобы отправить подборку гостю',
        whatsapp: 'WhatsApp',
        sms: 'sms',
        send_email: 'Отправить на Email',
        guest_phone: 'Телефон гостя',
        cart_is_send: 'Корзина отправлена',
        need_phone: 'Необходимо ввести телефон',
        whatsapp_error: 'Ошибка при создании ссылки',
    },
    expense : {
        title : 'Добавление расхода',
        date : 'Дата',
        sum : 'Сумма',
        category : 'Статья расхода',
        category_placeholder : 'Показать еще статьи',
        add_category : 'Название новой статьи',
        comment : 'Комментарий',
        apartments : 'Квартиры',
        apartments_placeholder : 'Поиск по названию',
        apartments_select_all : 'Общие расходы агентства',
        cancel : 'Закрыть окно',
        save : 'Добавить расход',
        saving : 'Добавление расхода...',
        new_category_name : 'Название категории',
    },
    date_picker : {
        default: 'Не задано',
        invalid_time: 'Неправильный формат времени',
    },
    date_picker_simple : {
        default: 'Не задано',
    },
    tags: {
        tags_list: 'Список тегов',
        title: 'Доступные теги:',
        close: 'Закрыть',
        guest_name: 'Имя гостя',
        checkin_date: 'Дата заезда',
        checkout_date: 'Дата выезда',
        address: 'Адрес объекта',
        address_eng: 'Адрес объекта (транслит)',
        how_to_get: 'Как добраться (настраиватеся в карточке объекта)',
        manager_name: 'Имя менеджера по заселению (настраивается в карточке объекта)',
        manager_name_eng: 'Имя менеджера по заселению (транслит)',
        manager_phone: 'Телефон менеджера по заселению (настраивается в карточке объекта)',
        agency_name: 'Название агентства',
        agency_phone: 'Телефон агентства',
        agency_email: 'Электронная почта агентства',
        links_booking: 'Ссылка для предоплаты',
        links_booking_eng: 'Ссылка для предоплаты (страница на английском)',
        wait_time: 'Срок оплаты',
        is_airbnb: 'Вывод содержимого только для брони с Airbnb',
        is_booking_com: 'Вывод содержимого только для брони с Booking.com',
        is_ostrovok: 'Вывод содержимого только для брони с Ostrovok.ru',
        is_oktogo: 'Вывод содержимого только для брони с Oktogo.ru',
        is_mention_prepaid: 'Вывод содержимого только для брони с выставлением предоплаты',
        is_agency_prepaid_settings: 'Вывод содержимого только, если у агентства настроены платежи'
    },
    attributes : {
        default : 'Не важно',
        reset : 'Сбросить все удобства',
    },
    modal_seasons: {
        title_edit: 'Редактирование сезона',
        title_add: 'Добавление сезона',
        name: 'Название',
        season_start: 'Дата начала сезона',
        season_end: 'Дата окончания сезона',
        add: 'Добавить',
        added: 'Добавляется...',
        edit: 'Редактировать',
        edited: 'Редактируется...',
        close: 'Закрыть'
    },
    drop_zone: {
        drag_files: 'Перетащите файлы',
        to_temp: 'в выделенную область',
        choose_files: 'Выберите файлы',
        or_drag: 'или перетащите их в выделенную область',
        max_size: 'Максимальный размер файла: {count}Мб',
        types: 'Допустимые типы:',
        loaded: 'Изображений загружено: {from} из {to}'
    },
    alert_modal: {
        cancel: 'Отменить',
        confirm: 'Подтвердить'
    },
    time_picker: {
        reset: 'Сбросить'
    },
    text_editor: {
        heading: 'Заголовок {number}',
        save: 'Сохранить',
        add_link: 'Добавьте ссылку: ',
        visit_url: 'Перейти по ссылке:',
        edit: 'Редактировать',
        remove: 'Удалить'
    },
    filezone: {
        label: 'Выбрать файлы',
        max_count: 'Максимальное количество файлов: {count}'
    },
    days: {
        today: 'сегодня',
        tomorrow: 'завтра',
        yesterday: 'вчера',
    },
    period_select: {
        this_week: 'Эта неделя',
        last_week: 'Прошлая неделя',
        this_month: 'Этот месяц',
        last_month: 'Прошлый месяц',
        past_three_months: 'Последние 3 месяца',
        this_year: 'Этот год',
        last_year: 'Прошлый год',
        all_time: 'За все время',
    },
    base_table: {
        total: 'Итого',
    },
    unknown_error: 'Неизвестная ошибка, попробуйте заново',
    connection_error: 'Отсутствует сигнал интернета',
    timeout_error: 'Превышено время выполнения запроса, попробуйте заново',
    hide_password: 'Скрыть пароль',
    show_password: 'Показать пароль',
    custom_timer: 'Через {time} можно выслать код заново',
    event_form : {
        title_create : 'Создание нового события',
        title_update : 'Редактирование',
        tab_create_common : 'Создание брони',
        tab_create_special : 'Настройка спец. условий',
        object : 'Объект размещения',
        load_not_found: 'Данные не найдены',
        create_error: 'Ошибка создания брони'
    },
    event_form_common : {
        tab_info_title : 'Информация',
        tab_moneta_title : 'Платеж (Moneta)',
        tab_wallet_title : 'Платеж (WalletOne)',
        tab_history_title : 'История брони',
        tab_automessages_title : 'Сообщения',
    },
    event_form_automessages_connect : {
        title : 'Подключите автосообщения',
        text : 'Автоматизируйте и экономьте время. Сократите количество ручных процессов и ошибок, допущенных человеком!',
        more : 'Узнать подробнее',
    },
    event_form_automessages: {
        sent : 'Отправлено {date}',
        failed : 'Не отправлено',
        will_be_sent : 'Будет отправлено {date}',
        whatsapp : 'WhatsApp {phone}',
        email : 'Email {email}',
        was_read : 'Прочитано',
        send_now : 'Отправить сейчас',
        send_email : 'Отправить на Email',
    },
    event_form_common_history : {
        title_version : 'Версия',
        title_updates : 'Изменения',
        event: 'Событие',
        version : 'Версия',
        updates : 'Изменения',
        ip : 'IP',
        user : 'Пользователь',
        user_full_name : 'Имя',
        user_email : 'Email',
        field : 'Поле',
        is_was : 'Было',
        became : 'Стало',
    },
    event_form_common_wallet : {
        no_data : 'Нет данных',
        amount: 'Сумма оплаты',
        status: 'Текущий статус',
        card_number: 'Номер карты',
        bind_card_number: 'Текущий номер привязанной карты',
        update_date: 'Время последнего изменения',
    },
    event_form_common_moneta: {
        no_data : 'Нет данных',
        amount: 'Сумма оплаты',
        human_state: 'Текущий статус',
        updated_at: 'Время последнего изменения',
        withdrew_at: 'Отправлено на СБП',
        refunded_at: 'Возвращено',
        paid_at: 'Время оплаты',
        refund: 'Сделать возврат',
        refund_confirm: 'Сделать возврат средств?',
    },
    event_form_special_create : {
        cancel : 'Отмена',
        save : 'Сохранить',
        saving : 'Сохранение...',
        close_without_saving: 'Закрыть без сохранения',
        restore_to_default: 'Вернуть значения по умолчанию',
        amount : 'Цена за сутки',
        min_stay_through : 'Мин. срок брони (сутки)',
        can_booking: 'Можно бронировать',
        can_in: 'Можно заезжать',
        can_out: 'Можно выезжать',
        apartments_select : 'Выберите...',
        rates: 'Тарифы',
        common_value: 'Общее значение',
        all_rates: 'Все тарифы',
        no_rates: 'Тарифы не выбраны',
        no_default_rates: 'Тарифы не установлены',
        select_all: 'Выбрать все',
        deselect_all: 'Убрать все',
        restore_rates: 'Вернуть значения по умолчанию',
        select_date_until: 'выбрать',
        save_not_found: 'Объект не найден',
        current_min_stay: 'Текущий мин. срок',
        min_stay_window: '{quantity} - окно бронирования',
        min_stay_actual: '{quantity} - спеццена',
        min_stay_dynamic: '{quantity} - из динамических ограничений',
        min_stay_base: '{quantity} - базовая',
        arrival_departure_close_warning: '{Важно!|{important_text}} Запрет заезда и выезда передаётся не на все площадки. {Подробнее|{more_info}}.'
    },
    event_form_common_info : {
        cancel : 'Отмена',
        save_create : 'Создать',
        saving_create : 'Создание...',
        save_update : 'Сохранить изменения',
        saving_update : 'Сохранение изменений...',
        delete : 'Удалить',
        room : 'Комната',
        price_by_day : 'Цена (сутки)',
        amount : 'Сумма',
        date_in : 'Заезд',
        date_out : 'Выезд',
        days_count : 'Итого: 0 суток | Итого: 1 сутки | Итого: {count} сутки | Итого: {count} суток | Итого: {count} суток',
        payed : 'Оплачено',
        deposit : 'Залог',
        status : 'Статус',
        info_about_dates_free : 'Даты останутся доступными для бронирования.',
        phone : 'Телефон',
        additional_phone : 'Доп. тел.',
        fio : 'Имя',
        notes : 'Примечание',
        email : 'Email',
        source : 'Источник',
        files : 'Файлы',
        color : 'Оформление',
        color_and_icon : 'Цвет и иконка',
        reset_visual : 'Сбросить',
        send_confirm_email : 'Отправить подтверждение на почту',
        send_link_prepaid : 'Отправить ссылку на предоплату',
        no_card_data : 'У Вас не настроены платёжные системы, для настройки перейдите на вкладку "Настройки" > "Прием платежей"',
        status_booked : 'Бронь',
        status_request : 'Заявка',
        status_canceled : 'Отмена',
        apartments_select : 'Выберите...',
        rooms_select : 'Выберите...',
        sources_select : 'Выберите...',
        confirm_delete : 'Удалить?',
        no_email_on_confirm: 'Необходимо заполнить поле',
        add_payment: 'Добавить учет денег',
        save_not_found: 'Событие не найдено',
        external_title:  'Бронь {title}',
        external_show_more:  'Показать все подробности',
        external_hide_more:  'Скрыть подробности',
        add: 'Добавить',
        confirm_email: 'Подтверждение на почту клиента',
        yandex_informer: 'Любые изменения в данной брони никак не отразятся на бронировании в канале Яндекс Путешествия. Для действий с исходным бронированием обратитесь в поддержку канала Яндекс Путешествия',
        automessages_enabled: 'Отправлять автосообщения',
    },
    event_info_payments_container: {
        remove_fine_or_refund_confirm_message: 'Обратите внимание! Записи о возврате залога и штрафе будут удалены',
        remove_payment_confirm_message: 'Обратите внимание! Запись о взятом залоге будет удалена'
    },
    event_info_payments_form : {
        amount: 'Сумма',
        fine_amount: 'Штраф',
        note: 'Комментарий',
        email: 'E-mail гостя',
        link: 'Ссылка',
        link_info: 'Будет доступна после сохранения платежа',
        link_inactive: 'Ссылка неактивна',
        account: 'Счет',
        agency_balance_error: 'На счету для оплаты услуги “Возврат” недостаточно средств',
        available_for_withdrawal: '(доступно для вывода {sum} @:agencyCurrency)',
        top_up: 'Пополнить',
        select_account: 'Выберите счет',
        create_payment: 'Создать платеж',
        create_deposit: 'Создать залог',
        refund_deposit: 'Вернуть залог',
        refund_payment: 'Вернуть платеж',
        save_payment: 'Сохранить платеж',
        save_deposit: 'Сохранить залог',
        cancel: 'Отменить',
        copy: 'Копировать',
        send_email: 'Выслать на e-mail гостя ссылку на оплату',
        get_link: 'Получить ссылку на оплату',
        manual_payment: 'Записать получение оплаты вручную',
        manual_refund_payment: 'Записать возврат оплаты вручную',
        manual_deposit_refund: 'Записать возврат залога вручную',
        manual_deposit: 'Записать получение залога вручную',
        payment_to_card: 'Вернуть оплату на карту',
        deposit_to_card: 'Вернуть залог на карту',
        payment: 'Платеж',
        select_payment: 'Выберите платеж',
        sum_of_date: '{sum} от {date}',
        stay: 'останется у вас',
        fine: 'вернется гостю',
        no_invoice_error: 'Необходимо выбрать платеж',
        payment_settings: 'Прием платежей',
        deposit: 'залог',
        add: 'добавить',
        refund: 'вернуть',
        moneta_active: 'подключена платежная система Монета',
        no_payment_systems: 'подключены платёжные системы',
        deposit_link_info: {
            text: 'За получение залога с помощью сервиса "Монета" будет списана комиссия {fee}',
            more: 'Подробнее',
            no_card_data : 'У Вас не {kind}, в данный момент вы можете {action} {type} вручную, для подключения и настройки перейдите на вкладку',
            canceled_event : 'У брони со статусом "Отмена" можно {action} {type} только вручную',
        },
        informers: {
            payments_moneta_to_manual: 'Обратите внимание! Весь платеж будет выведен на ваш СБП аккаунт, возврат денежных средств гостю вам необходимо провести самостоятельно.',
            moneta_to_payment_system: 'Обратите внимание! Вся сумма будет возвращена гостю на карту.',
            yandex_kassa_to_manual: 'Обратите внимание! Весь платеж останется на вашем аккаунте Юкасса, возврат денежных средств гостю вам необходимо провести самостоятельно.',
            payments_manual_to_manual: 'Обратите внимание! Возврат денежных средств гостю вам необходимо провести самостоятельно.',
            moneta_to_payment_system_full_amount: 'Обратите внимание! Средства будут зачислены гостю на карту в течение 3-5 дней.',
            moneta_to_payment_system_has_fine_amount: 'Обратите внимание! Средства будут зачислены гостю на карту в течение 3-5 дней, штраф будет выведен на ваш СБП аккаунт.',
            deposits_moneta_to_manual: 'Обратите внимание! Вся сумма залога будет выведена на ваш СБП аккаунт, возврат денежных средств гостю вам необходимо провести самостоятельно.',
            deposits_manual_to_manual: 'Обратите внимание! Возврат денежных средств гостю вам необходимо провести самостоятельно.',
        }
    },
    event_info_payments_table: {
        refund: 'возврат',
        fine: 'штраф',
        add_payment: 'Добавление платежа',
        add_deposit: 'Добавление залога',
        refund_payment: 'Возврат платежа',
        refund_deposit: 'Возврат залога',
        edit_payment: 'Редактирование платежа',
        edit_deposit: 'Редактирование залога',
        payment_link: 'Cсылка на оплату:',
        guest_email: 'E-mail гостя:',
        get_deposits_on_link: 'Принимайте залоги по ссылке онлайн',
        more_details: 'Узнать подробнее',
        no_access: {
            payments_refund: 'Для данной брони отсутствуют платежи, которые можно вернуть',
            deposits_refund: 'Для данной брони отсутствует залог, который можно вернуть',
            deposits_create: 'Для брони возможен только один залог',
        },
        payment_link_info: 'Обратите внимание! Ссылка на оплату становится активна только после сохранения брони. Пожалуйста, сохраните бронь перед отправкой ссылки гостю.',
        copy: 'Скопировать в буфер обмена',
        send_whatsapp: 'Отправить в WhatsApp',
        copied: 'Скопировано',
    },
    event_info_payments : {
        no_invoices : 'Не указан ни один счет.',
        date : 'Дата',
        amount : 'Сумма',
        invoice : 'Счет',
        comment : 'Комментарий',
        paid: 'Оплачено',
        deposit: 'Залог',
        debt: 'Задолженность',
        overpayment: 'Переплата',
        collapse: 'Свернуть',
        expand: 'Развернуть',
        add: 'Добавить залог',
        payment: 'Добавить платеж',
        fill_or_refund: 'Вернуть залог / Оштрафовать',
        refund: 'Вернуть платеж'
    },
}
